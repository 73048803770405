
































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getComponent, jsonParse } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";
import workspaceModule from "@/store/modules/workspaceModule";

@Component({
  components: {
    Multiselect,
  },
})
export default class ScheduleV2 extends mixins() {
  selectedColumnType = "DETAILS";
  userSelectionVal = "";
  yourValue = "";

  // NEEDED
  refreshResponseDataKey = 0;
  addShiftChecklistPopup = false;
  addShiftIsLoading = false;

  // NEEDED
  addShiftGroupSelected = "";

  // Loading views
  dataLoading = false;
  popupBackground = false;
  selectedDayOfWeek: any[] =[];
  daysOfWeek = [["Tue", "Tuesday"], ["Wed", "Wednesday"], ["Thu", "Thursday"], ["Fri", "Friday"], ["Sat", "Saturday"]];
  selectedTemplate = -1
  templateOptions: any[] = [];
  timeOptions: any[] = [];
  dayOptions: any[] = [];
  shiftEndTimeOptions: any[] = ["00:00"];
  recurEndTimeOptions: any[] = ["00:00"];
  userOptions: any[] = [];
  assetOptions: any[] = [];
  assetType = "";
  shiftDate = "";
  errorMessages = { shiftDate: "", recurStartDate: "", recurEndDate: "", weekdays: "", assetType: "", assetName: "", username: "" }
  dataErrorMessage = "";
  userName = "";
  assetName = "";
  shiftTemplates: any;
  allAssetTypes: any;
  shiftStartTime='';
  shiftEndTime='';
  recurStartDate='';
  recurEndDate='';
  recurType='';
  onDays=1;
  offDays=1;
  allDay = false;
  disableShiftStartTime = false;
  disableShiftEndTime = false;

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get scheduledRowComponents(): any[] {
      return scheduleModule.scheduledRowComponents
  }

  get shiftRowDetails() {
    return scheduleModule.addShiftRowDetails
  }

  changeAllDay(){
    this.allDay = !(this.allDay)
    if (this.allDay == false) {
      this.disableShiftStartTime = false;
      this.disableShiftEndTime = false;
    } else {
      this.disableShiftStartTime = true;
      this.disableShiftEndTime = true;
    }
  }

  setTimeOptions() {
    let value;
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j+=15) {
        if (j == 0) {
          if (i < 10) value = `0${i}:0${j}`; else value = `${i}:0${j}`;
        } else {
          if (i < 10) value = `0${i}:${j}`; else value = `${i}:${j}`;
        }
        this.timeOptions.push(value);
      }
    }
  }

  setDayOptions() {
    for (let i = 1; i <= 30; i++) {
      this.dayOptions.push(i);
    }
  }

  setShiftEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.shiftEndTimeOptions = this.timeOptions.slice();
      for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
        this.shiftEndTimeOptions.shift();
      }
      this.shiftEndTime = this.shiftEndTimeOptions[0];
    }
  }

  setRecurEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.recurEndTimeOptions = this.timeOptions.slice();
      for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
        this.recurEndTimeOptions.shift();
      }
    }
  }

  clickDayOfWeek(value) {
    
    if (this.selectedDayOfWeek.indexOf(value) === -1) {
      this.selectedDayOfWeek.push(value);
    } else {
      this.selectedDayOfWeek.splice(this.selectedDayOfWeek.indexOf(value),1)
    }
  }

  // NEEDED
  closeAddShiftPopup() {
    this.$emit("close-add-shift-popup");
  }

  async addShiftSave() {
    // @ts-ignore

    var errorFlag = false;
    for (var k in this.errorMessages) {
      this.errorMessages[k] = "";
    }

    if (this.shiftDate == "") {
      errorFlag = true;
      this.errorMessages.shiftDate = "Please select a data";
    }

    if (this.recurType != "") {
      if (this.recurStartDate == "") {
        errorFlag = true;
        this.errorMessages.recurStartDate = "Please select start date";
      }
      if (this.recurEndDate == "") {
        errorFlag = true;
        this.errorMessages.recurEndDate = "Please select end date";
      }
      if (this.selectedDayOfWeek.length == 0) {
        errorFlag = true;
        this.errorMessages.weekdays = "Please select weekdays";
      }
    }

    if (this.assetType == '') {
      errorFlag = true;
      this.errorMessages.assetType = "Please select asset type"
    }

    if (this.assetName == '') {
      errorFlag = true;
      this.errorMessages.assetName = "Please select asset name"
    }

    if (this.userName == '') {
      errorFlag = true;
      this.errorMessages.username = "Please select a user"
    }

    if (errorFlag)
      return;

    var startTime, endTime;
    if (this.selectedTemplate >= 0) {
      startTime = this.shiftTemplates[this.selectedTemplate].StartTime;
      endTime = this.shiftTemplates[this.selectedTemplate].EndTime;
      console.log(startTime, endTime)
      scheduleModule.addPersonnelScheduleShiftTemplates({
        name: this.shiftTemplates[this.selectedTemplate].Name,
        color: this.shiftTemplates[this.selectedTemplate].Color,
      })
    } else {
      if (this.allDay) {
        startTime = "00:00";
        endTime = "23:59";
      } else {
        startTime = this.shiftStartTime;
        endTime = this.shiftEndTime;
      }
    }

    scheduleModule.addPersonnelScheduleShifts({
      type: 'new',
      date: this.shiftDate,
      username: this.userName,
      startTime: startTime,
      endTime: endTime,
  		assetType: this.assetType,
	  	asset: this.assetName,
      recurType: this.recurType,
      recurStartDate: this.recurStartDate.substring(0, this.recurStartDate.length - 5), // "2022-04-01T01:00:00"
      recurEndDate: this.recurEndDate.substring(0, this.recurEndDate.length - 5), // "2022-04-05T01:00:00"
      weekdays: JSON.stringify(this.selectedDayOfWeek),
      onDays: this.onDays,
      offDays: this.offDays,
      workspace_id: scheduleModule.activePersonnelWorkspace.ID,
		  schedule_id: scheduleModule.activePersonnelSchedule.ID,
      selection_id: this.shiftRowDetails.title_row.selection_id,
      RowID: this.shiftRowDetails.title_row.selection_id
      // RowID: `0<>${this.shiftRowDetails.title_row.selection_id}`
    })
    /*await scheduleModule.postCustomShift({
      date: this.shiftDate,
      username: this.userName,
      startTime: startTime,
      endTime: endTime,
  		assetType: this.assetType,
	  	asset: this.assetName,
      recurType: this.recurType,
      recurStartDate: this.recurStartDate.substring(0, this.recurStartDate.length - 5), // "2022-04-01T01:00:00"
      recurEndDate: this.recurEndDate.substring(0, this.recurEndDate.length - 5), // "2022-04-05T01:00:00"
      weekdays: JSON.stringify(this.selectedDayOfWeek),
      onDays: this.onDays,
      offDays: this.offDays,
      workspace_id: scheduleModule.activePersonnelWorkspace.ID,
		  schedule_id: scheduleModule.activePersonnelSchedule.ID,
      rowID: rowDetails.title_row.selection_id
    });*/
    // @ts-ignore
    this.closeAddShiftPopup();
  }

  addShiftResponseData(addShiftResponseData: any) {
    throw new Error("Method not implemented.");
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setUserOptions(){
    this.userOptions.push(scheduleModule.accountDetails.map(item=>{
      return { value: item.email, text: item.name }
    })) 
  }
  async getAllAssetOptions(){
    var detailedComponent, currentComponent;
    this.allAssetTypes = {
      "Area": [],
      "Pad": [],
      "Well": [],
      "Route": []
    }
    for (var x = 0; x < this.scheduledRowComponents.length; x++) {
      detailedComponent = this.getFullComponentDetailsByID(this.scheduledRowComponents[x].title_row.selection_id)
      if (detailedComponent != null) {
        this.allAssetTypes[detailedComponent.AssetType].push(detailedComponent.Asset)
        if (this.shiftRowDetails && this.shiftRowDetails.title_row.selection_id == detailedComponent.ID)
          currentComponent = detailedComponent;
      }
		}

    if (currentComponent) {
      this.assetType = currentComponent.AssetType
      this.setAssetOptions()
      this.assetName = currentComponent.Asset
    }
    // this.allAssetTypes = await scheduleModule.getOperatorAssets();
  }
  getFullComponentDetailsByID(id) {
		if (scheduleModule.activePersonnelSchedule != null) {
			// @ts-ignore
			for (var x = 0; x < scheduleModule.activePersonnelSchedule.Rows.length; x++) {
					// @ts-ignore
        if (id == scheduleModule.activePersonnelSchedule.Rows[x].ID) {
          // @ts-ignore
          return scheduleModule.activePersonnelSchedule.Rows[x]
        }
      }
		}
	}
  async getShiftTemplates(){
    this.templateOptions = [{ value: -1, text: 'Custom' }];
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
    if (this.shiftTemplates) {
      for (let i=0; i<this.shiftTemplates.length; i++) {
        this.templateOptions.push({ value: i, text: this.shiftTemplates[i].Name })
      }
    }
  }
  async setAssetOptions(){
    if (this.allAssetTypes) {
      this.assetName = ''
      this.assetOptions = this.allAssetTypes[`${this.assetType}`]
    }
  }

  created() {
    this.setTimeOptions(), 
    this.setDayOptions(),
    this.setUserOptions(),
    this.getAllAssetOptions();
    this.getShiftTemplates();
  }
}
